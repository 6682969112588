html, body {
  height: 100%; /* Make sure parent elements are full height */
  margin: 0; /* Reset default margin */
}

.App {
  /* border: 1px solid green; */
  /* overflow: hidden; */
  height: 100dvh;
}

.chat-page {
  display: flex;
  flex-direction: column;
  height: 100dvh; 
  background-color: rgb(253, 253, 253);
  overflow-y: auto;
  /* border: 1px solid black; */
  overflow-x: hidden;
}


/* Header page */

ol {
  padding-inline-start: 0rem;  /* Adjusts the starting padding of the list to reduce indentation */
}

ol li::marker {
  color: #777777;
  font-size: 1rem;
}

body:not(.history-pane-open) .chat-area {
  margin-left: 0;
}

ol li {
  margin-bottom: 1.2rem;  /* Increases the space between list items */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px; /* You can adjust the height as needed */
  padding: 0 20px; /* Add padding on the left and right for spacing */
  border-bottom: 0.5px solid lightgrey;
}

.fa-house, 
.fa-bars  {
  color: black;
}

.menu-button-small-screen,
.new-session-button-small-screen,
.new-session-button-small-screen-book-detail {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.country-select {
  padding: 4px 8px;
  border-radius: 4px;
  border: 0.1px solid #ccc;
  background-color: rgb(251, 251, 251);
  cursor: pointer;
  margin: 0 10px; /* Adjust spacing as needed */
  color: black;
}

.country-select:focus {
  outline: none; /* Removes the default focus outline */
}


/* chat area */

.book-author {
  font-size: 0.9rem; /* Smaller font size for the author's name */
  color: rgb(104, 103, 103); /* Different color for the author's name */
}

.fa-star-half-stroke {
  color: orange;
  font-size: 0.9rem;
}

.fa-solid.fa-star {
  color: orange;
  font-size: 0.9rem;
}

.fa-regular.fa-star {
  color: orange;
  font-size: 0.9rem;
}

.review-count {
  font-size: 0.85rem;
}

.ratings-and-review {
  display: flex;
  align-items: center;
}

.star-rating {
  margin-right: 10px; /* Adjust as needed */
}

/* Comment List component */

.comment-panel {
  max-height: 95vh;
  margin-top: 1.1rem;
  /* border: 1px solid green; */
}

.comment-header {
  position: sticky; /* Make the header stick to the top of the viewport */
  top: 0; /* Set the top position to align with the top of the scroll container */
  background-color: white; /* Optional: Ensure the header has a background so it isn't see-through */
  z-index: 100; /* Set a z-index to make sure it stacks on top of other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 1.1rem 1rem 0.8rem;
}


.comments-count-heading {
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 0.4rem;
}

.comment-header .fa-x {
  font-size: 1.05rem;
  margin-top: 0.2rem;
  color: rgb(179, 179, 179);
  cursor: pointer;
}

.comments-count {
  /* font-size: 0.9rem; */
  color: grey;
}

.comment-input-form-container {
  width: 95%;
  /* border: 1px solid pink; */
  margin: auto;
}

.comment-input-form {
  margin-bottom: 2rem;
  width: 100%;
  /* border: 1px solid pink; */
}

.comment-input-form textarea {
  border: 0.5px solid grey;
  border-radius: 0.5rem;
  height: 4rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  padding: 0.4rem;
  resize: none; /* Prevents resizing. You can change it to 'vertical' if you want to allow vertical resizing only */
}

/* Comment component */

/* This will be the container for the avatar and the comment details */
.comment-container {
  display: flex;
  align-items: flex-start; /* Aligns items to the top */
  /* border: 1px solid green; */
  width: 95%;
  margin: auto;
}

.user-avatar {
  width: 1.7rem; /* Adjust the width as necessary */
  height: 1.7rem; /* Adjust the height as necessary */
  margin-right: 1rem; /* Adds some space between the avatar and the comment */
  border-radius: 50%;
}

.reply-container {
  display: flex; /* Activates Flexbox */
  align-items: flex-start; /* Aligns items at the top of the container */
}

.comment-reaction {
  margin-bottom: 1.5rem;
}

.comment-sender {
  margin-bottom: 0.6rem;
  font-weight: bold;
  font-size: 0.9rem;
}

.comment-text {
  margin-bottom: 0.85rem;
  font-size: 0.95rem;
  white-space: pre-wrap;
}

.comment-reply-button {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 0.8rem;
}

.comment-like {
  margin-right: 0.2rem;
}

.comment-like-count {
  font-size: 0.88rem;
}

.comment-dislike {
  margin-left: 0.9rem;
}

.reply textarea {
  border: 0.5px solid grey;
  border-radius: 0.5rem;
  height: 4rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  padding: 0.4rem;
  resize: none;
}


/* Book Detail component */

.book-detail {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px;
  margin-left: 1.7rem;
  margin-right: 1.7rem;
}

@media (min-width: 768px) {
  .book-detail {
    grid-template-columns: 1fr 1fr; /* Main book gets more space, similar books get less */
  }
}

@media (min-width: 992px) {
  .book-detail {
    grid-template-columns: 1fr 1fr; /* Increasing space for the main book on larger devices */
  }
}

@media (min-width: 1200px) {
  .book-detail {
    grid-template-columns: 1fr 1fr; /* Further increasing space for the main book on extra large screens */
  }
}

@media (min-width: 1440px) {
  .book-detail {
    grid-template-columns: 1fr 1fr; /* Maximum allocation for main book on very large screens */
  }
}

.main-book {
  /* border: 1px solid green; */
}

.main-book-container {
  margin: auto;
  width: 20rem;        /* Fixed width as before */
  height: auto;        /* Height automatically adjusts */
  margin-top: 1.5rem;    /* Keeps top margin */
  /* border: 1px solid green; */
}

.main-book img {
  /* border: 1px solid green; */
  cursor: pointer;
}

.book-buttons {
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  justify-content: center
}

.book-buttons button {
  margin-bottom: 1rem;
}

.similar-books {
  /* border: 1px solid green; */
  margin-top: 1.5rem; 
}


/* Home component */

.home {
  display: flex;
  /* border: 1px solid green; */
  padding: 0rem 1.5rem;
  flex-direction: column;
  overflow-y: auto; 
  overflow-x: hidden;
  flex: 1;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5); /* White background with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it covers other content */
}

.genre-bar {
  display: flex;
  flex: none;
  overflow-x: auto;
  white-space: nowrap;
  padding: 15px 0;
  position: sticky;
  top: 0;
  background-color: white;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.genre-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.genre-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.genre-item {
  display: inline-block;
  padding: 6px 15px;
  margin-right: 10px;
  background: rgb(235, 235, 235);
  border-radius: 0.5rem;
  font-size: 0.93rem;
  cursor: pointer;
  transition: background 0.3s;
}

.genre-item:hover {
  background: #ccc;
}

.genre-item.selected {
  background: black;
  color: white;
}

.book-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.5rem, 1fr)); /* Creates a responsive grid with each column minimum 200px wide */
  gap: 2.5rem; /* Space between books */
  padding: 0px 0px 1.5rem 0px;
  flex: 1;
  /* border: 1px solid red; */
}

.book-item {
  display: flex;
  flex-direction: column;/* Center content horizontally */
  /* align-items: flex-start; */
  gap: 8px;
  margin: auto;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 8px;
  max-height: 25rem; 
  min-width: 10rem;
  max-width: 10rem;
  margin-top: -0.1px;
  /* border: 1px solid black; */
}

.main-book .book-item {
  /* border: 1px solid green; */
  margin-bottom: 1.5rem;
}

.book-item img {
  width: 100%; /* Make image fill the container */
  height: auto;
  max-height: 14rem;
  min-height: 14rem; /* Limit image height */
}

/* Additional styling for text content */
.book-item div {
  font-size: 14px;
  color: black;
}

.book-item .title {
  font-weight: 600;
  font-size: 0.98rem;
  line-height: 1.5rem;
  margin-bottom: -0.2rem;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.book-item .author {
  font-weight: 400;
  color: #6b6b6b;
}

.book-item .review-count {
  font-weight: 400;
  color: #6b6b6b;
  margin-left: -0.6rem;
}


.home::-webkit-scrollbar {
  width: 0.5rem; 
}

.home::-webkit-scrollbar-thumb {
  background: #dcdcdc; 
  border-radius: 10rem;
}

.home::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0; 
}

/* Chat component */

.chat-area {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-left: 260px; /* Keep your existing left margin */
  padding-bottom: 5rem; /* Added padding at the bottom */
  overflow-y: auto; 
  flex: 1;
  padding-right: 3rem;
}

.chat-area-wrapper {
  display: flex;
  justify-content: center; /* Center the child horizontally */
}

.chat-area::-webkit-scrollbar {
  width: 0.5rem; 
}

.chat-area::-webkit-scrollbar-thumb {
  background: #dcdcdc; 
  border-radius: 10rem;
}

.chat-area::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0; 
}

.chat-heading {
  text-align: center; 
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 5dvh;
}

/* Sample Queries */

body:not(.history-pane-open) .sample-queries-container {
  left: 0;
}

.sample-queries-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  position: fixed;
  left: 260px;
  right: 0px;
  max-width: 41.5rem; /* Same as input-area */
  margin-left: auto; /* Centering with auto margins */
  margin-right: auto; /* Centering with auto margins */
  border-radius: 8px;
  margin-bottom: 0.5rem;
  
}

.sample-queries-row {
  display: flex;
  justify-content: space-evenly; /* Evenly space the buttons */
  margin-bottom: 0.5rem; /* Space between the rows */
}

.sample-query {
  /* Existing styles */
  flex: 1;
  margin: 0.1rem 0.4rem;
  border-radius: 0.6rem;
  height: 3.7rem; /* Adjusted to a single height value */
  background: none;
  border: 1.4px solid rgba(169, 169, 169, 0.416);
  font-size: 0.9rem;
  text-align: start;
  padding: 0.9rem;
  cursor: pointer;
  color: black;
}

.sample-query:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Light grey color on hover */
}

/* input area */
body:not(.history-pane-open) .input-area {
  margin-left: 0;
}

.input-area {
  display: flex;
  flex-direction: column;
  margin-left: 260px;
  align-items: center;
  background-color: rgb(253, 253, 253);
  padding: 0 0.5rem;
}

.disclaimer-text {
  font-size: 0.75rem;
  color: grey;
  margin-bottom: 0.5rem; 
  margin-top: 0.4rem;
}

.input-box-container {
  display: flex;        /* Enables Flexbox layout */
  align-items: flex-end;  /* Vertically centers the children */
  justify-content: space-between; /* Spaces children out; can adjust as needed */
  width: 98%;
  margin: auto;
  position: relative;
  border: 0.093rem solid #D3D3D3;
  border-radius: 0.8rem;
  max-width: 45rem;
}

.input-box {
  width: 100%; /* Full width of the parent container */
  /* max-width: 40rem; */
  margin: auto;
  border: none;
  border-radius: 0.8rem;
  height: auto; /* Adjust height automatically based on content */
   /* Border color and width */
  outline: none; /* Removes the default focus outline */
  resize: none; /* Disables the resize handle */
  font-family: 'Arial', sans-serif; /* Font style */
  font-size: 1rem; /* Font size */
  line-height: 1.5; /* Line height for text */
  color: #333; /* Text color */
  background-color: #fff; /* Background color */
  padding: 0.6rem 0.2rem 0.6rem 1rem;
  max-height: 10rem;
  overflow-y: auto;

  /* Placeholder styling */
  ::placeholder {
      color: #888; /* Color of the placeholder text */
  }

  ::-webkit-scrollbar {
      width: 6px; 
  }

  ::-webkit-scrollbar-thumb {
      background-color: #e6e4e4;
      border-radius: 3px; 
  }

  :focus {
      border-color: #007BFF; 
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); 
  }
}

.send-button, .stop-button {
  font-size: 0.9rem;
  width: 1.7rem;
  height: 1.7rem;
  
}

.stop-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.15rem;
  color: black;
  margin-bottom: 0.46rem;
  margin-right: 0.65rem;
}

.send-button {
  background-color: #ddd;
  border: 1px solid #D3D3D3;
  border-radius: 0.5rem;
  color: white;
  /* background-color: green; */
  margin-bottom: 0.52rem;
  margin-right: 0.6rem;
}

.send-button.active {
  background-color: black;
  cursor: pointer;
}

.fa-arrow-up {
  margin: auto !important;
}

/* History pane css */

.close-pane-button {
  position: absolute;
  top: 50%; 
  left: 107%; 
  transform: translate(-50%, -50%);
  cursor: pointer; 
  color: rgb(200, 200, 200);
  font-size: 1.3rem;
  background: none;
  border: none;
}

.close-pane-button:hover {
  color: black; 
}

.pane-line path {
  transition: d 2s ease, stroke-width 2s ease;
  stroke-linecap: round;
}

.history-pane {
  background-color: rgb(251, 251, 251);
  /* color: #fff; */
  width: 244px; 
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10000; 
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;
  border-right: 1px solid lightgrey;
}

.history-pane::-webkit-scrollbar-thumb {
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
}

/* Firefox */
.history-pane {
  scrollbar-width: thin;
  scrollbar-color: rgb(236, 236, 236) #f1f1f1;
}

.history-pane.closed {
  transform: translateX(-100%); 
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align buttons vertically */
  position: relative; 
  &:hover {
    background-color: hsla(0,0%,100%,.1);
    border-radius: 0.34rem;
  }
  cursor: pointer;
}

.fa-arrow-up {
  margin: auto;
}

.new-session-button {
  color: rgb(101, 101, 101);
  border: none;
  cursor: pointer;
  background: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.64rem !important;
  /* border: 1px solid blue; */
}

.history-content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1; 
  margin-bottom: 3.7rem;
}

.history-content::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 27%;
  /* background: linear-gradient(to left, rgba(0, 0, 0, 1), transparent); */
  pointer-events: none; 
}

.history-entry {
  overflow: hidden; 
  white-space: nowrap;
  padding: 0.6rem 0.4rem;
  margin-left: 0.2rem;
  margin-right: 0.73rem;
  cursor: pointer; 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  z-index: 1;
}

.history-entry:hover {
  position: relative;
  z-index: 2; 
  background-color: hsla(240, 51%, 79%, 0.213);
  border-radius: 0.5rem;
}

.history-entry-text {
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-right: 1rem !important;
  font-size: 0.88rem !important;
  
}

.delete-session-button {
  display: none;
  cursor: pointer;
  color: #d9d9e3;
  background: none;
  border: none;
  font-size: 0.8rem;
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  padding: 0rem;
  margin-right: 0.3rem;
  margin-left: 0;
}

.history-entry:hover .delete-session-button,
.history-entry.active .delete-session-button {
  display: inline-block;
}


.history-entry:hover .delete-session-button,
.history-entry.active .delete-session-button {
  display: inline-block;
}

.user-info-container {
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.7rem;
  position: relative;
  height: 3rem;
  width: 91%;
  margin-left: 4%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.user-entry {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
  &:hover { 
      background-color: hsla(240, 51%, 79%, 0.213);
      border-radius: 0.5rem;
  }
  user-select: none;
}

.user-entry.active {
  background-color: hsla(240, 51%, 79%, 0.213);
  border-radius: 0.5rem;
}

.dummy-user-entry {
  background-color: hsla(240, 51%, 79%, 0.213);
  border-radius: 0.5rem;
}

.history-pane-log-in-message {
  font-size: 0.95rem;
  font-weight: bold;
  padding: 0.5rem 0.5rem 0rem 0.9rem;
  margin-bottom: 0.8rem;
  line-height: 1.4rem;
  /* border: 1px solid red; */
}

.history-pane-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 0.33rem;
  margin-right: 0.8rem;
  user-select: none;
}

.dropdown-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 80.8%;
  margin-left: 0.8%;
  margin-bottom: 0.5rem;
  z-index: 3;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden; /* Optional: In case of very long item names */
  font-size: 0.97rem;
  border: 0.5px solid lightgrey;
}

.dropdown-menu li {
  list-style-type: none;
  margin-left: -2.45rem;
  padding: 0.6rem 1.5rem;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: hsla(240, 51%, 79%, 0.213);
  }
  height: 1.7rem;
}

.dropdown-menu .fa-arrow-right-from-bracket {
  margin-right: 1.15rem;
  margin-left: 0.33rem;
}

.dropdown-menu .fa-comments {
  margin-right: 1.07rem;
  margin-left: 0.08rem;
}



.dropdown-menu .fa-book {
  margin-right: 1.31rem;
  margin-left: 0.23rem;
}

.dropdown-menu .fa-address-book {
  margin-right: 1.25rem;
  margin-left: 0.23rem;
}



.dropdown-menu .fa-file {
  margin-right: 1.08rem;
  margin-left: 0.355rem;
}

.dropdown-menu .fa-blog {
  margin-right: 0.93rem;
  margin-left: 0.31rem;
}

.dropdown-menu .fa-trash {
  margin-right: 1.31rem;
  margin-left: 0.39rem;
}

.dropdown-menu .fa-cart-shopping {
  margin-right: 1.22rem;
  margin-left: 0.14rem;
}

.dropdown-menu .fa-envelope {
  margin-right: 1.03rem;
  margin-left: 0.19rem;
}

.dropdown-link {
  color: inherit; 
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 0.45rem;
}

.unseen-messages-count {
  margin-left: 8px;
  background-color: green; 
  color: white;
  border-radius: 50%; 
  padding: 2.5px 7px; 
  font-size: 0.65rem; 
  vertical-align: middle; 
  font-weight: bold;
}

.history-entry.active {
  background-color: hsla(240, 51%, 79%, 0.213);
  border-radius: 0.5rem;
  position: relative;
  z-index: 2;
}

.category-title {
  margin: 0.52rem 0.67rem;
  font-size: 0.73rem;
  color: rgb(152, 152, 152);
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 2rem;
  font-weight: bold;
}

.confirmation-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000000000;
}

.confirmation-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 0.9rem;
  z-index: 1000000000;
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.confirmation-dialog button {
  margin: 0 10px;
  padding: 0.5rem 15px;
  border: none;
  border-radius: 0.5rem;
  width: 6rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirmation-dialog button:hover {
  background-color: #0056b3;
}

.confirmation-dialog button:nth-child(2) {
  background-color: #dc3545;
}

.confirmation-dialog button:nth-child(2):hover {
  background-color: #c82333;
}

/* UpgradePlanModal */

.upgrade-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.443);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upgrade-modal-content {
  background: white;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 3.3rem;
  z-index: 10000000;
  border-radius: 0.7rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}

.modal-heading {
  margin-left: 1.63rem;
}

.plans-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upgrade-plan-option {
  flex: 1;
  padding: 20px 26px 10px 26px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e7e7e7; /* Only right border for separation */
  border-bottom: 1px solid #e7e7e7;
}

.upgrade-plan-option h3 {
  margin-bottom: 0.7rem;
}

.upgrade-amount {
  color: rgb(71, 71, 71);
  margin-top: 0;
  margin-bottom: 1.95rem;
}

.upgrade-plan-option:first-child {
  border-right: 1px solid #e7e7e7; /* Ensure the first item has a right border */
}

.upgrade-plan-option:last-child {
  border-right: none; /* Remove right border for the last item */
}

.upgrade-button, .current-plan-button, .cancel-plan-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 1rem;
  font-size: 0.98rem;
  cursor: pointer;
  margin: 0.2rem 0 0.7rem 0;
}

.upgrade-button {
  background-color: #00a400; /* Initial green color */
  color: white; /* White text color */
}

.upgrade-button:hover {
  background-color: #008c00;
}

.current-plan-button, .cancel-plan-button {
  background-color: white;
  border: 0.5px solid lightgrey;
  color: rgb(97, 97, 97);
  cursor: not-allowed;
}

.cancel-plan-button {
  background-color: black;
  color: white;
  cursor: pointer;
}

.current-plan-button:hover {
  background-color: rgb(242, 242, 242);
}

.close-button {
  cursor: pointer;
  border: none;
  background: none;
}

.close-button i {
  margin-right: 1.5rem;
  color: black;
  font-size: 1.25rem !important;
}

.features-list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  font-size: 0.87rem;
  line-height: 2rem;
}

.features-list li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.fa-check {
  margin-right: 10px;
}

.manage-subscription-link {
  font-size: 0.8rem; /* Smaller font size */
  color: #6f6f6f; /* Lighter font color */
  margin-top: 10px; /* Space from the list above */
  cursor: pointer; /* Makes it clear it's clickable */
  text-decoration: underline; /* Underline text */
}

/* Manage subscription page */

.manage-subscription-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.7;
  font-size: 1rem;
  padding: 5px 30px 30px 30px;
  max-width: 680px;
  margin: auto;
  border-radius: 8px;
  margin: 3rem auto;
}

.manage-subscription-page h1,
.manage-subscription-page h2 {
  color: #333;
  margin: 20px 0;
}

.manage-subscription-page h2 {
  margin-top: 4rem;
}

.manage-subscription-page p,
.manage-subscription-page li {
  margin: 20px 0;
}

.manage-subscription-page a {
  color: #0066cc;
  text-decoration: none;
}

.manage-subscription-page a:hover {
  text-decoration: underline;
}

.manage-subscription-page ol,
.manage-subscription-page ul {
  padding-left: 40px;
}

.manage-subscription-page ul {
  list-style-type: disc;
}


/* header with home button */

.home-button {
  background: none;
  border: none;
  font-size: 1.4rem;
  color: rgb(62, 62, 62);
  padding: 0.87rem 1rem 0.87rem 1.83rem;
  cursor: pointer;
}

/* Home page components */

.homepage-parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  flex-grow: 1;
  overflow: hidden;
  
}


.home-page-header {
  display: flex;
  align-items: center;
  border: 0.7px solid lightgrey;
}

/* Specific class for the clickable area */
.clickable-area {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home-page-header img {
  width: 1.7rem;
  height: auto;
  margin: 0.8rem 1rem 0.8rem 1.5rem;
}

.home-page-header .clickable-area span {
  font-size: 1.2rem;
  font-weight: bold;
}


.hero {
  text-align: center;
  padding: 0.2rem  0rem 3.1rem  0rem;
  width: 93dvw;
  max-width: 640px;
  margin: 6.5rem auto;
  /* background-color: rgb(242, 242, 242); */
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */

  /* border: 0.7px solid lightgrey; */
  border-radius: 1rem;
}

.hero h2 {
  
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  line-height: 2.15rem;
}

.carousel {
  width: 93dvw;
  max-width: 640px;
  max-height: 92.5dvh;
  overflow: hidden; 
  /* background-color: rgb(242, 242, 242); */
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
  border: 0.7px solid lightgrey;
  border-radius: 1rem;
  padding: 0rem 0rem 1.25rem 0rem;
  margin: 6.5rem auto;
}

.carousel img {
  max-width: 88%; /* You already have this, but ensuring it's mentioned */
  margin: 0.5rem auto;
  height: auto; /* Maintains aspect ratio */
  display: block; /* Removes bottom space/gap under images */
}

.features-div {
  width: 93dvw;
  max-width: 640px;
  margin: auto;
  line-height: 2.2rem;
}

.features-carousel {
  width: 93dvw;
  max-width: 640px;
  max-height: 95dvh;
  margin: 6.5rem auto;
  overflow: hidden; 
  /* background-color: rgb(242, 242, 242); */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  */
  border: 0.7px solid lightgrey;
  border-radius: 1rem;
  padding: 0rem 0rem 0.7rem 0rem;
}

.features-carousel img {
  max-width: 88%; /* You already have this, but ensuring it's mentioned */
  margin: auto;
  height: auto; /* Maintains aspect ratio */
  display: block; /* Removes bottom space/gap under images */
}

.feature {
  text-align: center;
}

.feature:focus {
  outline: none;  /* Removes the default outline */
  border: none;   /* Removes any border if applied */
}


.feature-text {
  margin-bottom: 1.5rem;
  
}

.feature-text h3 {
  margin-bottom: -0.3rem;
}

.feature-text p {
  margin-bottom: -0.3rem;
  margin-left: 1.8rem;
  margin-right: 1.8rem;
  text-align: center;
  line-height: 1.4rem;
}


/* Hide the ::before pseudo-element for the default slick arrows only */
.slick-next::before,
.slick-prev::before {
  content: none !important;
}

/* Make sure your custom arrows are visible */
.sample-next-arrow, .sample-prev-arrow {
  display: block !important;
  /* Your other styling properties */
}

/* If your custom arrows have the slick-next and slick-prev classes, 
   you might need to reset the ::before pseudo-element for them as well */
.sample-next-arrow.slick-next::before,
.sample-prev-arrow.slick-prev::before {
  content: '' !important; /* Reset the content for your custom arrows if necessary */
}

.footer {
  /* background-color: rgb(242, 242, 242); */
  border: 0.7px solid lightgrey;
  text-align: center;
  padding: 1.4rem 0rem 1.4rem 0rem;
  position: relative;
  font-size: 0.88rem;
}

.footer nav a {
  margin-left: 0.85rem;
  margin-right: 0.85rem;
  text-decoration: none;
  color: #333;
  /* border: 1px solid black; */
  padding: 0.5rem 0rem;
}

.footer nav a:first-child {
  margin-left: 0; /* Remove left margin for the first link */
}

.footer nav a:last-child {
  margin-right: 0; /* Remove right margin for the last link */
}

.footer nav span {
  color: #333; /* Same color as the links for consistency */
}

.comparison {
  margin: 5rem auto 6.3rem auto;
  padding: 0.1rem 1.5rem 0.5rem 1.5rem;
  border-radius: 8px;
  width: 90dvw;
  max-width: 800px;
  line-height: 1.65rem;
  font-size: 0.95rem;
  /* border: 1px solid red; */
}

.comparison h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.comparison table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  */
  border: 0.7px solid lightgrey;
  border-radius: 1rem; 
}

.comparison th, .comparison td {
  padding: 1rem 0.7rem;
  text-align: left;
  /* border-bottom: 0.7px solid lightgrey; */
}

.comparison th {
  /* background-color: #e8e8e8; */
}

.comparison tbody tr:nth-child(odd) {
  /* background-color: #fbfbfb; */
}

.comparison tbody tr:nth-child(even) {
  /* background-color: rgb(242, 242, 242);  */
}

.comparison td:first-child {
  font-weight: bold; /* This will apply bold styling only to the first cell in each row, which are the feature names */
}

/* Styles for the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Decreasing the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Making the scrollbar track invisible */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(219, 219, 219);
  border-radius: 4px; /* Optional: Adds rounded corners to the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b0b0b0; /* Optional: Changes thumb color on hover for better user experience */
}

/* Styles for the right arrow on the home page */

.home-page-left-arrow {
  padding: 0.2rem;
  /* border: 1.5px solid lightgrey; */
  color: rgb(190, 190, 190);
  font-size: 1.1rem;
  position: absolute;
  top: 50%;
  left: 92%;
  transform: translate(-50%, -50%);
}

.home-page-right-arrow {
  padding: 0.2rem;
  /* border: 1.5px solid lightgrey; */
  color: rgb(190, 190, 190);
  font-size: 1.1rem;
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translate(-50%, -50%);
}

/* login page components */

/* Default state styling for the resend email button */
.resend-email-button {
  background-color: transparent; /* No background by default */
  color: #4CAF50; /* Green text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s, color 0.2s; /* Smooth transition for hover effect */
  padding: 9px 11px; /* Padding around the text */
  font-size: 0.85rem; /* Text size */
  font-weight: bold;
  margin-bottom: 0px !important;
  border: none;
  margin-top: 0.15rem;
}

/* Hover state styling for the resend email button */
.resend-email-button:hover {
  background-color: #e0f2f1; /* Light green background on hover */
  border-radius: 0.5rem;
}

.email-sent-confirmation {
  font-size: 0.85rem;
  margin-top: 1.4rem;
}

.email-input, .continue-button {
  background-color: #ffffff; /* For the input, set a white background */
  padding: 10px 15px; /* Match padding */
  font-size: 1rem; /* Match font size */
  border-radius: 0.5rem; /* Match border radius */
  border: 0.5px solid grey; /* Match border style */
  width: 239px; /* Match width */
  height: 1.8rem; /* Match height */
  margin-bottom: 15px; /* Space between input and button */
  display: block; /* Ensure it takes the full width */
}

.continue-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  display: flex; /* Flex display to center content */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  width: 305px !important;
  height: 3.2rem !important;
  margin-bottom: 0px !important;
}

/* Adjust the hover effect for the continue button */
.continue-button:hover {
  background-color: #367c39; /* Darker green on hover */
}

.login-link {
  text-decoration: none; /* No underline by default */
  color: #4CAF50; /* Color to match the expected design */
}

.login-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between stacked buttons */
  width: 300px;
  margin: auto;
  margin-bottom: -1rem;
  /* border: 1px solid blue; */
}

.login-button {
  background-color: #ffffff; /* white background */
  padding: 10px 15px; /* Adjust padding */
  font-size: 1rem;
  border-radius: 0.5rem; /* Border radius to match the image */
  border: 1px solid #f5f5f5;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  width: 300px; /* Fixed width for buttons */
  transition: background-color 0.3s, color 0.3s;
  border: 0.5px solid grey;
  height: 3.2rem;
  color: black;
}

.login-button:hover {
  background-color: rgb(238, 237, 237);
}

.login-button:focus {
  outline: none;
  border-color: #4285f4; /* Border color on focus */
}

.google-logo, .microsoft-logo, .apple-logo {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1.2rem; /* Set margin to the right of the logo */
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;
  font-size: 0.75rem;
}

.divider .line {
  flex-grow: 1;
  height: 1px;
  background-color: #a9a9a9;
  margin: 0 8px;
  min-width: 132px;
}

/* Email Verification Page css start */

.email-verification-container {
  display: flex;
  justify-content: center; /* Align horizontally at the center */
  align-items: center; /* Align vertically at the center */
  height: 100vh; /* Full viewport height */
  padding: 20px;
}

.email-verification-box {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 10px 25px 25px 25px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  border-radius: 8px;
}

.email-verification-box h2 {
  color: #333;
  margin-bottom: 10px;
}

.email-verification-box p {
  color: black;
  font-size: 0.98rem;
  line-height: 1.55rem;
  margin-bottom: 20px;
}

.email-verification-input {
  width: calc(100% - 20px); /* Adjust width considering padding */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in the element's total width */
  font-size: 0.95rem;
  transition: border-color 0.3s ease-out; /* Smooth transition for focus */
}

.email-verification-input:focus {
  outline: none; /* Removes default focus outline */
  border-color: #5c67f2; /* A blue border color when focused */
  box-shadow: 0 0 5px rgba(92, 103, 242, 0.5); /* Adds a subtle glow around the input */
}


.email-verification-signin-btn,
.email-verification-resend-btn {
  width: calc(100% - 20px); /* Ensure buttons are of the same width as input */
  padding: 10px;
  border: none;
  background-color: rgba(0, 0, 255, 0.74);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Include padding and border in the element's total width */
  font-size: 0.95rem;
}


.email-verification-signin-btn:hover,
.email-verification-resend-btn:hover {
  background-color: #4a54e1;
}

.email-verification-signin-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.email-verification-resend-btn {
  background-color: #f3f3f3;
  color: #333;
}

.email-verification-resend-btn:hover {
  background-color: #e2e2e2;
}

.email-verification-error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.email-verification-confirmation {
  font-size: 0.9rem;
  color: #28a745;
}



/* Email verification page css end */



.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90dvh; 
  text-align: center;
  padding: 1rem;
}

.verification-message {
}

.verification-message h2 {
  font-size: 2rem;
}

.verification-message p {
  font-size: 1rem;
  line-height: 1.7rem;
}

.login-container .error-message {
  margin-bottom: 0.5rem;
  margin-top: -0.83rem;
  color: red; 
  font-size: 0.8rem;
  margin-left: 0.45rem;
  overflow-wrap: break-word; /* Ensures the words break and wrap to the next line */
  word-break: break-word; /* Use this for older browsers */
  white-space: normal; /* Allows the text to wrap to the next line */
  line-height: 1.2; /* Adjust line height for better readability */
  max-width: 250px; /* Set a max-width or use 100% if you want it to fill its container */
}

.error-message {
  margin-bottom: 1rem !important;
}


.email-input-group {
  position: relative;
  display: flex;
}

.email-input-group input {
  height: 1.6rem;
}

/* Ensure the password input does not overlap the toggle button */
.email-input {
  flex-grow: 1;
  padding-right: 50px; /* Adjust padding to not overlap the toggle button */
}

.input-error {
  border: 1px solid red;
}

.email-input:focus {
  outline: none;
}

/* Onboarding Page */

.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: #FAFAFA; /* Assuming you want the same background color */
  /* border: 1px solid blue; */
}

.onboarding-container form {
  min-width: 280px; /* Adjust the width as necessary */
  margin: 0 auto;
  /* border: 1px solid red; */
}

.onboarding-container h1 {
  font-size: 2rem; /* Adjust the font size as necessary */
  margin-bottom: 20px; /* Adjust the spacing as necessary */
  color: #333; /* Assuming a darker color for the heading */
  text-align: center;
}

.onboarding-container input[type="text"] {
  width: 89%;
  padding: 15px;
  margin-bottom: 10px; /* Space between the input fields */
  border-radius: 8px;
  border: 1px solid #ccc; /* Light grey border */
  font-size: 1rem;
}

.onboarding-container input[type="text"]:focus {
  border-color: #4CAF50; /* Changes border color to green when focused */
  outline: none; /* Optionally remove the outline */
}

.onboarding-container button {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  color: white;
  background-color: #4CAF50; /* Green background */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.onboarding-container button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.bold-text {
  font-weight: bold;
}

.dropdown {
  width: 100%;
  position: relative;
  font-size: 1rem;
  /* border: 1px solid red; */
}

.dropdown-button {
  /* width: 100%; */
  margin-bottom: 10px;
  padding: 15px 15px 15px 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  text-align: left;
  /* border: 1px solid red; */
}

.dropdown-button.active {
  border-color: #4CAF50; /* Green border when active */
}


.dropdown-list {
  position: absolute;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  background-color: white;
  z-index: 1000;
  max-height: 200px; /* Adjust this value to control the max height */
  overflow-y: auto; /* Enables scrolling */
}

.dropdown-list li {
  padding: 15px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

/* lightbox component */

.lightbox-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.823);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000; 
}

.lightbox-content {
  background-color: white;
  padding:  2% 6.7% 3% 6.7%;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 580px;
  max-height: 94dvh; 
  overflow-y: auto; 
  position: relative; 
  font-size: 1rem;
  color: #0D0D0D;
  line-height: 1.7rem;
}

.lightbox-content {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.lightbox-content::-webkit-scrollbar { 
  display: none;  /* Chrome, Safari, Opera*/
}

.lightbox-content-for-image img {
  max-width: 630px;
}

.lightbox-close {
  position: fixed;
  top: 1.5rem;
  right: 0.6rem;
  cursor: pointer;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.848);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
}

.fa-xmark {
  color: white;
  font-size: 0.8rem !important;
}

/* AnswerDisplay Edit functionality */

.answer-display-textarea {
  width: 100%;
  height: auto; 
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  border: none;
  resize: none;
  margin-left: 0.05rem;
  margin-top: -0.12rem;
  background-color: rgb(253, 253, 253);
}

.answer-display-textarea:focus {
  outline: none; 
}

.button-container {
  display: flex; 
  justify-content: center !important; 
  align-items: center; 
  margin-top: -0.55rem !important;
  margin-bottom: 0.4rem;
  border: 1px solid red;
}

.fa-pen {
  display: none;
  color: rgb(191, 189, 189);
  cursor: pointer;
  padding: 6px;
  font-size: 0.8rem;
  position: absolute;
  left: -0.35rem; 
}

.fa-pen:hover {
  color: black;
}

.message-question-container:hover .fa-pen {
  display: block;
}

/* AnswerDisplay component */

.fa-user {
  padding: 0.11rem;
}

.message {
  display: flex;
  align-items: flex-start; 
  max-width: 600px;
  font-size: 1rem;
  color: #0D0D0D;
  line-height: 1.7rem;
  width: 100%;
  margin-left: 1rem;
}

.message-content img {
  margin-top: 0.8rem;
}

.message-content h3 {
  margin-bottom: -0.001rem;
}

.message-answer img {
  width: 145.625px;
  height: 208.33333333333334px;
  cursor: pointer; 
}


.message-question-container {
  position: relative;
  margin-left: 0.18rem;
  padding-bottom: 1.9rem;
}

.message-answer {
  padding-bottom: 2.9rem;
}

.message-question {
  white-space: pre-wrap;
  color: #0D0D0D;
}

.message-icon {
  margin-right: 0.8rem; 
}

.display-image {
  margin-top: 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: none;
}

.message-content {
  flex-grow: 1;
}

.message-sender {
  font-weight: bold; 
  margin-bottom: -1.4rem;
  user-select: none;
  display: block;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  width: 18rem;
  margin-bottom: -0.4rem;
  /* border: 1px solid green; */
}

.blog-image-container {
  margin-top: 1rem;
  width: 220px;
}

.image-container {
  width: 220px;
}

.image-container img {
  height: 13.17rem;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  margin: auto;
  margin-left: 2rem;
  padding-top: 0.1rem;
}

.buttons-container.no-image {
  margin: 0;
}

.getbooks-buy-now-button-invisible, .getbooks-buy-now-button, .buy-now-button, .key-insights-btn, .anecdotes-btn, .quotes-btn, .preview-btn, .more-details-btn, .continue-generating-btn {
  background: none;
  border: 0.5px solid lightgrey;
  font-size: 0.82rem;
  padding: 0.25rem 0.6rem;
  border-radius: 0.35rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  width: 7.8rem;
  color: #0D0D0D;
}

.getbooks-buy-now-button-invisible {

  visibility: hidden;
}

.continue-generating-btn {
  width: 7rem;
  padding: 0.45rem 0.5rem;
  margin-right: 1.15rem;
  border: 0.5px solid grey;
}

.fa-forward {
  margin-right: 0.3rem;
  font-size: 0.82rem !important;
}

.getbooks-buy-now-button:hover, .buy-now-button:hover, .key-insights-btn:hover, .anecdotes-btn:hover, .quotes-btn:hover, .preview-btn:hover, .more-details-btn:hover, .continue-generating-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.getbooks-buy-now-button:active, .buy-now-button:active, .key-insights-btn:active, .anecdotes-btn:active, .quotes-btn:active, .preview-btn:active, .more-details-btn:active, .continue-generating-btn:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.getbooks-buy-now-button:focus, .buy-now-button:focus, .key-insights-btn:focus, .anecdotes-btn:focus, .quotes-btn:focus, .preview-btn:focus, .more-details-btn:focus, .continue-generating-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.save-button, .cancel-button {
  padding: 0.25rem 0.6rem;
  text-align: center;
  text-decoration: none;
  font-size: 0.82rem;
  margin: 0.4rem 0.3rem;
  cursor: pointer;
  border-radius: 0.35rem;
}

.save-button {
  background-color: #10A37F; 
  border: none;
  color: white;
}

.cancel-button {
  border: 1px solid lightgrey;
  background-color: inherit;
}

.save-button:hover {
  background-color: #0d7f62;
}

.cancel-button:hover { 
  background-color: rgba(0, 0, 0, 0.06); 
}

.button-container-continue-generating-btn {
  flex: 1;
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
  width: 100%; 
  margin-top: -1.5rem;
}

.single-button {
  margin-bottom: 1.5rem;
}

/* Active Streaming */
 
.streaming .more-details-btn,
.streaming .key-insights-btn,
.streaming .anecdotes-btn,
.streaming .quotes-btn,
.streaming .preview-btn,
.streaming .getbooks-buy-now-button,
.streaming a {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none; /* Prevents clicking */
}

.lightbox-content .buy-now-button {
  display: none;
}

.streaming img {
  cursor: not-allowed;
  pointer-events: none; 
}

.lightbox-content img {
  margin-top: 0.8rem;
  width: 135.625px;
  height: 208.33333333333334px;
  margin-bottom: 0.6rem;
}

.blog-lightbox-content img {
  margin-top: 0.8rem;
  width: 135.625px;
  height: 208.33333333333334px;
}

.lightbox-content button {
  width: 139px;
  margin-bottom: 1.4rem !important;
}

/* BlogPostPage.css */

.blogPostContainer {
  max-width: 40rem;
  margin: auto;
  padding: 4rem 2rem;
}

.blogPostContainer h1 {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.7rem;
}

.topmost-blog-image {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
  border-radius: 0.8rem;
}

.blogPostContainer div {
  /* margin-bottom: 20px; */
  line-height: 1.8rem;
  font-size: 1rem;
}

.blogPostContainer ol {
  padding-inline-start: 1.2rem;  /* Adjusts the starting padding of the list to reduce indentation */
}

/* BlogPage.css */

.blog-page {
  padding: 5.5rem 2.5rem 9.5rem 2.5rem;
}

.blog-page-heading {
  text-align: left; /* Changed to left align */
  font-size: 2rem;
  line-height: 110%;
  font-weight: bold;
}

/* Add a style for the horizontal line */
.blog-page hr {
  border: 0;
  height: 0.7px;
  background-color: rgba(0, 0, 0, 0.127);
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.blog-posts-container {
  display: grid;
  gap: 20px;
  /* border: 1px solid green; */
}

/* Large screens: 3 per row */
@media (min-width: 992px) {
  .blog-posts-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Medium screens: 2 per row */
@media (min-width: 768px) and (max-width: 991px) {
  .blog-posts-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog-post-preview {
  border-radius: 8px;
  margin-bottom: 20px;
  background: #fff; 
  overflow: hidden;
}

.blog-post-preview img {
  width: 100%;
  height: auto; 
  min-height: 16.5rem;
  max-height: 16.5rem;
  display: block; 
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.2s ease; 
}

.blog-post-preview .blog-title {
  padding: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-top: 0.5rem;
  font-weight: bold;
}

.blog-post-preview:hover img {
  transform: scale(1.04);
}

.blog-post-link {
  text-decoration: none;
  color: inherit;
}

/* Contact us page */

.entire-contact-us-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the footer to the bottom */
  padding-bottom: 0.01rem;
}

.contact-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.7;
  font-size: 1.05rem;
  padding: 25px 30px 30px 30px;
  max-width: 680px;
  margin: auto; /* Center vertically and horizontally in the available space */
  border-radius: 8px;
}

.contact-container h2 {
  color: #333;
}

.contact-container p {
  margin: 20px 0;
}

.contact-details {
  background-color: #f9f9f9;
  border-left: 5px solid rgb(236, 236, 236);
  padding: 10px;
  margin-top: 30px;
}


/* About us page */

.entire-about-us-page {
  padding-bottom: 0.01rem;
}

.about-container {
  
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.7;
  font-size: 1.05rem;
  padding: 5px 10px 30px 10px;
  max-width: 680px;
  margin: 3rem auto 4rem auto;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
}

.about-container h1, .about-container h2 {
  color: #333;  /* Darker gray to maintain readability and consistency */
}

.about-container p {
  margin: 20px 0;
}

.mission-statement {
  background-color: #f9f9f9 ;  /* Light gray to maintain theme uniformity */
  border-left: 5px solid rgb(236, 236, 236); 
  padding: 10px;
  margin-top: 30px;
}


/* privacy policy */

.privacyPolicy {
  /* font-family: 'Arial', sans-serif; */
  color: #333;  /* Dark gray text for good readability */
  line-height: 1.8rem;
  padding: 20px;
  padding-top: 0.5rem;
  /* background-color: rgb(248, 248, 248);  */
  max-width: 800px;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1);  */
  /* border: 0.7px solid lightgrey; */
}

.header-privacy-policy {
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
  color: #333; 
  margin-bottom: 2rem;
}

section-privacy-policy h2 {
  color: #333;
  font-size: 1.3rem;
}

section-privacy-policy h3 {
  color: rgb(156, 156, 156);
}

section-privacy-policy p {
  margin: 10px 0;
}

section-privacy-policy ul {
  list-style-type: square;
  padding-left: 20px;
}

section-privacy-policy li strong {
  color: #333;
}



/* header with back button */

.back-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: rgb(186, 186, 186);
  padding: 0.55rem 1rem 0.55rem 1.05rem;
  cursor: pointer;
}

.header-with-back-button {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-bottom: 0.8px solid lightgrey;
  padding: 0rem;
  z-index: 100;
}

@media (max-width: 578px) {
  .home-page-left-arrow {
    left: 75%;
  }
  .home-page-right-arrow {
    left: 25%;
  }
}


@media (max-width: 759px) {
  .comparison td {
    hyphens: auto;
    word-break: break-word; /* Allows word breaking */
    text-align: left;
  }

  .about-container {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

  .chat-page {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .homepage {
    overflow: hidden;
  }

  .fa-pen {
    display: block;
  }

  .sample-queries-container {
    left: 0 !important;
  }

  .sample-query {
    height: 6rem;
  }

  .blog-posts-container {
    grid-template-columns: 1fr;
  }

  .close-pane-button {
    display: none;
  }

  .fa-angle-left {
    display: none;
  }
  
  .fa-angle-right {
    display: none;
  }

  .chat-area {
    margin: 0;
    margin-left: -0.27rem !important;
    padding-left: 0rem;
    padding-top: 2.5dvh;
    padding-right: 4dvw;
    
    /* border: 1px solid blue; */
  }

  .input-area {
    margin: 0;
  }

  .lightbox-content {
    max-width: 75%;
  }


  .lightbox-content {
    max-width: 100dvw !important;
  }

  .lightbox-content-for-image img {
    width: 80dvw;
  }

  .history-pane {
    width: 16rem;
  }

  .lightbox-close {
    top: 1.3rem;
    right: 1.3rem;
    width: 1.1rem;
    height: 1.1rem;
  }
  
  .fa-xmark {
    font-size: 0.7rem;
  }
}